<template>
  <div class="no-survey">
    <h1>No se encontr&oacute; ninguna encuesta</h1>
    <p>Tu organizaci&oacute;n no cuenta con ninguna encuesta activa por el momento.</p>

    <custom-button variant="primary" @click="logout">
      Aceptar
    </custom-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CustomButton from '../components/CustomButton.vue';

export default {
  name: 'NoSurvey',

  components: { CustomButton },

  computed: {
    ...mapState(['company', 'survey']),
  },

  methods: {
    ...mapActions(['logout']),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.company.activeSurveyId) return next();

      const endDate = new Date(`${vm.survey.endDate}T00:00:00`.replace(/-/g, '/').replace(/T.+/, '')).toISOString().slice(0, 10);

      const today = new Date().toISOString().slice(0, 10);

      if (endDate >= today) return next('/welcome');

      return next();
    });
  },
};
</script>

<style>
.no-survey {
  display: grid;
  place-content: center;
  place-items: center;
  grid-gap: var(--lengthMd3);
  gap: var(--lengthMd3);
}

h1 {
  color: var(--mainColor1);
}
</style>
